export const USER_WITHDRAWAL_ACTION_REQUEST = 'USER_WITHDRAWAL_ACTION_REQUEST';
export const USER_WITHDRAWAL_ACTION_SUCCESS = 'USER_WITHDRAWAL_ACTION_SUCCESS';
export const USER_WITHDRAWAL_ACTION_FAILURE = 'USER_WITHDRAWAL_ACTION_FAILURE';

export const WITHDRAWALS_LIST_REQUEST = 'WITHDRAWALS_LIST_REQUEST';
export const WITHDRAWALS_LIST_SUCCESS = 'WITHDRAWALS_LIST_SUCCESS';
export const WITHDRAWALS_LIST_FAILURE = 'WITHDRAWALS_LIST_FAILURE';

export const GET_AVAILABLE_WITHDRAWALS_REQUEST = 'GET_AVAILABLE_WITHDRAWALS_REQUEST';
export const GET_AVAILABLE_WITHDRAWALS_SUCCESS = 'GET_AVAILABLE_WITHDRAWALS_SUCCESS';
export const GET_AVAILABLE_WITHDRAWALS_FAILURE = 'GET_AVAILABLE_WITHDRAWALS_FAILURE';

export const SET_WITHDRAWALS_DEFAULTS = 'SET_WITHDRAWALS_DEFAULTS';