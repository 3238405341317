import React, {Suspense } from 'react';
import {Switch, Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import authRoutes from "../authRoutes";
import Page404 from "./layout/404";

import Notifications from 'react-notify-toast';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

const AlgoLayout = Loadable({
    loader: () => import('./layout/AlgoLayout'),
    loading: Loader
});

const App = () => {

        const authRouter = authRoutes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : null;
        });

        return (
            <Aux>
                <div style={{position: "relative"}}>
                {/*<ScrollToTop>*/}
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {authRouter}
                            <Route exact={true} path="/404" component={Page404} />
                            <Route path="/black" component={AlgoLayout} />
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                {/*</ScrollToTop>*/}
                <Notifications options={{zIndex: 200}}/>
                </div>
            </Aux>
        );
}

export default App;
