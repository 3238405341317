export const USER_ALGO_GET_PERSONAL_INFO_REQUEST = 'USER_ALGO_GET_PERSONAL_INFO_REQUEST';
export const USER_ALGO_GET_PERSONAL_INFO_SUCCESS = 'USER_ALGO_GET_PERSONAL_INFO_SUCCESS';
export const USER_ALGO_GET_PERSONAL_INFO_FAILURE = 'USER_ALGO_GET_PERSONAL_INFO_FAILURE';
export const USER_ALGO_UPDATE_PERSONAL_INFO_REQUEST = 'USER_ALGO_UPDATE_PERSONAL_INFO_REQUEST';
export const USER_ALGO_UPDATE_PERSONAL_INFO_SUCCESS = 'USER_ALGO_UPDATE_PERSONAL_INFO_SUCCESS';
export const USER_ALGO_UPDATE_PERSONAL_INFO_FAILURE = 'USER_ALGO_UPDATE_PERSONAL_INFO_FAILURE';

export const USER_GET_BANK_DETAILS_REQUEST = 'USER_GET_BANK_DETAILS_REQUEST';
export const USER_GET_BANK_DETAILS_SUCCESS = 'USER_GET_BANK_DETAILS_SUCCESS';
export const USER_GET_BANK_DETAILS_FAILURE = 'USER_GET_BANK_DETAILS_FAILURE';
export const USER_ADD_BANK_DETAILS_REQUEST = 'USER_ADD_BANK_DETAILS_REQUEST';
export const USER_ADD_BANK_DETAILS_SUCCESS = 'USER_ADD_BANK_DETAILS_SUCCESS';
export const USER_ADD_BANK_DETAILS_FAILURE = 'USER_ADD_BANK_DETAILS_FAILURE';
export const USER_UPDATE_BANK_DETAILS_REQUEST = 'USER_UPDATE_BANK_DETAILS_REQUEST';
export const USER_UPDATE_BANK_DETAILS_SUCCESS = 'USER_UPDATE_BANK_DETAILS_SUCCESS';
export const USER_UPDATE_BANK_DETAILS_FAILURE = 'USER_UPDATE_BANK_DETAILS_FAILURE';
export const USER_GET_WALLET_REQUEST = 'USER_GET_WALLET_REQUEST';
export const USER_GET_WALLET_SUCCESS = 'USER_GET_WALLET_SUCCESS';
export const USER_GET_WALLET_FAILURE = 'USER_GET_WALLET_FAILURE';
export const USER_ADD_WALLET_REQUEST = 'USER_ADD_WALLET_REQUEST';
export const USER_ADD_WALLET_SUCCESS = 'USER_ADD_WALLET_SUCCESS';
export const USER_ADD_WALLET_FAILURE = 'USER_ADD_WALLET_FAILURE';
export const SET_PROFILE_DETAILS_DEFAULT = 'SET_PROFILE_DETAILS_DEFAULT';
