import * as userActions from './constants.js'
import {handleRespErr, handleRespErrWithCb} from '../../../helpers/respErrorsHandler'
import {toastError, toastSuccess} from '../../../helpers/toaster';
import {GetAxios} from '../../../helpers/axios';
import {API_URLS, buildAPIUrl, URLS_LOCAL_GROUPS} from "../../../urls";
import axios from 'axios';
import {deleteLoginCookies, setLoginCookies} from "../../../helpers/cookies";


export function userSignInAction(email, password) {

    return dispatch => {
        dispatch(userSignInActionRequest());

        const userData = {
            email: email,
            password: password
        };

        axios.defaults.withCredentials = true;

        axios.post(buildAPIUrl(API_URLS.signIn), userData, {
            headers: {
                'Content-Type': 'application/json',
                'Front-Host': 'client'
            }
        })
            .then((res) => {
                let cookies = {
                    loggedIn: {
                        value: true,
                        options: {

                        }
                    },
                    accessToken: {
                        value: res.data.access_token,
                        options: {

                        }
                    },
                    user_id: {
                        value: res.data.public_id,
                        options: {

                        }
                    },
                    first_name: {
                        value: res.data.user_name?.first_name,
                        options: {

                        }
                    },
                    last_name: {
                        value: res.data.user_name?.last_name,
                        options: {

                        }
                    },
                    user_public_id: {
                        value: res.data.public_id,
                        options: {

                        }
                    },
                    is_email_confirmed: {
                        value: res.data.is_email_confirmed,
                        options: {

                        }
                    }
                }
                setLoginCookies(cookies);
                dispatch(userSignInActionSuccess(res.data))

            })
            .catch(handleRespErr(dispatch, toastError, userSignInActionFailure))
    }
}

function userSignInActionRequest() {
    return {
        type: userActions.USER_SIGN_IN_REQUEST,
    }
}

function userSignInActionSuccess(userData) {
    return {
        type: userActions.USER_SIGN_IN_SUCCESS,
        payload: userData
    }
}

function userSignInActionFailure(error) {
    return {
        type: userActions.USER_SIGN_IN_FAILURE,
        error: error
    }
}

export function userSignOutAction() {

    return dispatch => {
        let axios = GetAxios(dispatch);

        axios.put(buildAPIUrl(API_URLS.signOut), {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                deleteLoginCookies();
                dispatch(userSignOutSuccess());
            })
            .catch(handleRespErr(dispatch, toastError, userSignOutFailure))
    }
}

function userSignOutFailure() {
    return {
        type: userActions.USER_SIGN_OUT_FAILURE
    }
}

export function userSignOutSuccess() {
    return {
        type: userActions.USER_SIGN_OUT_SUCCESS
    }
}

export function userSignUpAction(data) {

    return dispatch => {
        dispatch(userSignUpActionRequest());

        axios.post(buildAPIUrl(API_URLS.signUp), data, {
            headers: {
                'Content-Type': 'application/json',
                "Front-Host": "client"
            }
        })
            .then((res) => {

                dispatch(userSignUpActionSuccess(res.data));

                let cookies = {
                    loggedIn: {
                        value: true,
                        options: {}
                    },
                    accessToken: {
                        value: res.data.access_token,
                        options: {

                        }
                    },
                    user_id: {
                        value: res.data.public_id,
                        options: {

                        }
                    },
                    first_name: {
                        value: res.data.user_name?.first_name,
                        options: {

                        }
                    },
                    last_name: {
                        value: res.data.user_name?.last_name,
                        options: {

                        }
                    },
                    user_public_id: {
                        value: res.data.public_id,
                        options: {

                        }
                    },
                    is_email_confirmed: {
                        value: res.data.is_email_confirmed,
                        options: {

                        }
                    }
                }
                setLoginCookies(cookies)
                dispatch(userSignUpActionSuccess(res.data))
            })
            .catch(handleRespErr(dispatch, toastError, userSignUpActionFailure))
    }
}

function userSignUpActionRequest() {
    return {
        type: userActions.USER_SIGN_UP_REQUEST,
    }
}

function userSignUpActionSuccess(userData) {
    return {
        type: userActions.USER_SIGN_UP_SUCCESS,
        payload: userData
    }
}

function userSignUpActionFailure(error) {
    return {
        type: userActions.USER_SIGN_UP_FAILURE,
        error: error
    }
}

export function confirmEmailAction(data, cb) {

    return dispatch => {
        let axios = GetAxios(dispatch);
        dispatch(confirmEmailActionRequest());

        axios.post(buildAPIUrl(API_URLS.confirmEmail), data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(confirmEmailActionSuccess())
                let cookies = {
                    is_email_confirmed: {
                        value: true,
                        options: {}
                    }
                }
                setLoginCookies(cookies);
                dispatch(userSignInActionSuccess({is_email_confirmed: true}))
            })
            .catch(err => {
                dispatch(confirmEmailActionFailure(err.response?.data?.error))
                // cb('Something went wrong')
                console.log(err.response?.data?.error)
            })
    }
}

function confirmEmailActionRequest() {
    return {
        type: userActions.USER_CONFIRM_EMAIL_ACTION_REQUEST,
    }
}

function confirmEmailActionSuccess() {
    return {
        type: userActions.USER_CONFIRM_EMAIL_ACTION_SUCCESS,
    }
}

function confirmEmailActionFailure(error) {
    return {
        type: userActions.USER_CONFIRM_EMAIL_ACTION_FAILURE,
        payload: error
    }
}

export function sendConfirmationLink(id) {

    return dispatch => {
        let axios = GetAxios(dispatch);
        dispatch(sendConfirmationLinkRequest());

        axios.post(buildAPIUrl(API_URLS.sendConfirmationLink), id, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(sendConfirmationLinkSuccess());
                toastSuccess('Confirmation link has been sent.')
            })
            .catch(handleRespErr(dispatch, toastError, sendConfirmationLinkFailure))
    }
}

function sendConfirmationLinkRequest() {
    return {
        type: userActions.USER_SEND_CONFIRM_EMAIL_LINK_REQUEST,
    }
}

function sendConfirmationLinkSuccess() {
    return {
        type: userActions.USER_SEND_CONFIRM_EMAIL_LINK_SUCCESS,
    }
}

function sendConfirmationLinkFailure(error) {
    return {
        type: userActions.USER_SEND_CONFIRM_EMAIL_LINK_FAILURE,
        error: error
    }
}

export function userSendResetEmailAction(email, cb) {
    return dispatch => {

        dispatch(userSendResetEmailActionRequest())

        axios.post(buildAPIUrl(API_URLS.sendResetEmailLink), email, {
            headers: {
                'Content-Type': 'application/json',
                'Front-Host': 'client'
            }
        })
            .then(res => {
                dispatch(userSendResetEmailActionSuccess());
                toastSuccess('A password reset link has been sent to your email.')
            })
            .catch(handleRespErr(dispatch, toastError, userSendResetEmailActionFailure))
    }
}

function userSendResetEmailActionRequest() {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_REQUEST,
    }
}

function userSendResetEmailActionSuccess(message) {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_SUCCESS,
    }
}

function userSendResetEmailActionFailure() {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_FAILURE,
    }
}

export function onSetNewPassword(password, router) {
    return dispatch => {

        dispatch(onSetNewPasswordRequest());

        axios.post(buildAPIUrl(API_URLS.setPassword), password, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(onSetNewPasswordSuccess());
                toastSuccess(res.data.message || 'Password has been changed');
                router.push(URLS_LOCAL_GROUPS.signIn)
            })
            .catch(handleRespErr(dispatch, toastError, onSetNewPasswordFailure))
    }
}

function onSetNewPasswordRequest() {
    return {
        type: userActions.USER_SET_NEW_PASSWORD_REQUEST
    }
}

function onSetNewPasswordSuccess() {
    return {
        type: userActions.USER_SET_NEW_PASSWORD_SUCCESS
    }
}

function onSetNewPasswordFailure() {
    return {
        type: userActions.USER_SET_NEW_PASSWORD_FAILURE
    }
}

export function getOptions() {
    return dispatch => {
        let axios = GetAxios(dispatch);
        dispatch(getOptionsRequest());

        axios.get(buildAPIUrl(`${API_URLS.options}/clients`), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(getOptionsSuccess(res.data))
            })
            .catch(handleRespErr(dispatch, toastError, getOptionsFailure))
    }
}

function getOptionsRequest() {
    return {
        type: userActions.GET_OPTIONS_REQUEST,
    }
}

function getOptionsSuccess(options) {
    return {
        type: userActions.GET_OPTIONS_SUCCESS,
        payload: options
    }
}

function getOptionsFailure(error) {
    return {
        type: userActions.GET_OPTIONS_FAILURE,
        payload: error
    }
}

export function signUpProduct(data, router, platform) {

    return dispatch => {
        let axios = GetAxios(dispatch);
        dispatch(signUpProductRequest());

        axios.post(buildAPIUrl(API_URLS.signUpProduct), {...data, is_practice_mode: false}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(signUpProductSuccess(res.data, platform));
                router.push('/details?step=4')
            })
            .catch(handleRespErrWithCb(dispatch, toastError, signUpProductFailure, () => router.push('/details?step=4')))
    }
}

function signUpProductRequest() {
    return {
        type: userActions.SIGN_UP_PRODUCT_REQUEST,
    }
}

function signUpProductSuccess(product, platform) {
    return {
        type: userActions.SIGN_UP_PRODUCT_SUCCESS,
        payload: {...product, ...platform}
    }
}

function signUpProductFailure(error) {
    return {
        type: userActions.SIGN_UP_PRODUCT_FAILURE,
        payload: error
    }
}

export function signUpDemoProduct(data, router, platform) {

    return dispatch => {
        let axios = GetAxios(dispatch);
        dispatch(signUpDemoProductRequest());

        axios.post(buildAPIUrl(API_URLS.signUpProduct), {...data, is_practice_mode: true}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(signUpDemoProductSuccess(res.data, platform));
                router.push('/details?step=4')
            })
            .catch(handleRespErrWithCb(dispatch, toastError, signUpDemoProductFailure, () => router.push('/details?step=4')))
    }
}

function signUpDemoProductRequest() {
    return {
        type: userActions.SIGN_UP_DEMO_PRODUCT_REQUEST,
    }
}

function signUpDemoProductSuccess(product, platform) {
    return {
        type: userActions.SIGN_UP_DEMO_PRODUCT_SUCCESS,
        payload: {...product, ...platform}
    }
}

function signUpDemoProductFailure(error) {
    return {
        type: userActions.SIGN_UP_DEMO_PRODUCT_FAILURE,
        payload: error
    }
}

export function signInProduct(data, cb, change_to_live) {
    return dispatch => {
        let axios = GetAxios(dispatch);
        !data.is_practice_mode ? dispatch(signInProductRequest()) : dispatch(signInDemoProductRequest());

        axios.post(buildAPIUrl(API_URLS.signInProduct), data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                !data.is_practice_mode ? dispatch(signInProductSuccess(res.data)) : dispatch(signInDemoProductSuccess(res.data));
                !change_to_live && window.open(res.data.redirect_url, '_blank', 'noreferrer')
                cb && cb()
            })
            .catch(handleRespErr(dispatch, toastError, !data.is_practice_mode ? signInProductFailure : signInDemoProductFailure))
    }
}

function signInProductRequest() {
    return {
        type: userActions.SIGN_IN_PRODUCT_REQUEST,
    }
}

function signInProductSuccess(product) {
    return {
        type: userActions.SIGN_IN_PRODUCT_SUCCESS,
        payload: product
    }
}

function signInProductFailure(error) {
    return {
        type: userActions.SIGN_IN_PRODUCT_FAILURE,
        payload: error
    }
}

function signInDemoProductRequest() {
    return {
        type: userActions.SIGN_IN_DEMO_PRODUCT_REQUEST,
    }
}

function signInDemoProductSuccess(product) {
    return {
        type: userActions.SIGN_IN_DEMO_PRODUCT_SUCCESS,
        payload: product
    }
}

function signInDemoProductFailure(error) {
    return {
        type: userActions.SIGN_IN_DEMO_PRODUCT_FAILURE,
        payload: error
    }
}
