import * as optionsActions from '../../actions/optionsAction/constants';

const initialState = {
    constants: null,
    platforms: null,
    currencies: null,
    products: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case optionsActions.GET_OPTIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case optionsActions.GET_OPTIONS_SUCCESS:
            return {
                ...state,
                constants: action.payload,
                isLoading: false
            };
        case optionsActions.GET_OPTIONS_FAILURE:
            return {
                ...state,
                constants: null,
                isLoading: false
            };
        case optionsActions.GET_PLATFORMS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case optionsActions.GET_PLATFORMS_SUCCESS:
            return {
                ...state,
                platforms: action.payload,
                isLoading: false
            };
        case optionsActions.GET_PLATFORMS_FAILURE:
            return {
                ...state,
                platforms: null,
                isLoading: false
            };
        case optionsActions.GET_PRODUCTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case optionsActions.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload,
                isLoading: false
            };
        case optionsActions.GET_PRODUCTS_FAILURE:
            return {
                ...state,
                products: null,
                isLoading: false
            };
        case optionsActions.GET_CURRENCIES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case optionsActions.GET_CURRENCIES_SUCCESS:
            return {
                ...state,
                currencies: action.payload,
                isLoading: false
            };
        case optionsActions.GET_CURRENCIES_FAILURE:
            return {
                ...state,
                currencies: null,
                isLoading: false
            };
        case optionsActions.CLEAR_PLATFORMS:
            return {
                ...state,
                products: null
            };
        default:
            return state
    }
}