export const USER_SIGN_IN_REQUEST = 'USER_SIGN_IN_REQUEST';
export const USER_UNCOMPLETED_SIGN_IN_SUCCESS = 'USER_UNCOMPLETED_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAILURE = 'USER_SIGN_IN_FAILURE';

export const USER_SIGN_UP_REQUEST = 'USER_SIGN_UP_REQUEST';
export const USER_SIGN_UP_SUCCESS = 'USER_SIGN_UP_SUCCESS';
export const USER_SIGN_UP_FAILURE = 'USER_SIGN_UP_FAILURE';

export const USER_CONFIRM_EMAIL_ACTION_REQUEST = 'USER_CONFIRM_EMAIL_ACTION_REQUEST';
export const USER_CONFIRM_EMAIL_ACTION_SUCCESS = 'USER_CONFIRM_EMAIL_ACTION_SUCCESS';
export const USER_CONFIRM_EMAIL_ACTION_FAILURE = 'USER_CONFIRM_EMAIL_ACTION_FAILURE';

export const USER_SEND_CONFIRM_EMAIL_LINK_REQUEST = 'USER_SEND_CONFIRM_EMAIL_LINK_REQUEST';
export const USER_SEND_CONFIRM_EMAIL_LINK_SUCCESS = 'USER_SEND_CONFIRM_EMAIL_LINK_SUCCESS';
export const USER_SEND_CONFIRM_EMAIL_LINK_FAILURE = 'USER_SEND_CONFIRM_EMAIL_LINK_FAILURE';

export const USER_SIGN_UP_STEP_ONE_REQUEST = 'USER_SIGN_UP_STEP_ONE_REQUEST';
export const USER_SIGN_UP_STEP_ONE_SUCCESS = 'USER_SIGN_UP_STEP_ONE_SUCCESS';
export const USER_SIGN_UP_STEP_ONE_FAILURE = 'USER_SIGN_UP_STEP_ONE_FAILURE';

export const USER_SIGN_UP_STEP_TWO_REQUEST = 'USER_SIGN_UP_STEP_TWO_REQUEST';
export const USER_SIGN_UP_STEP_TWO_SUCCESS = 'USER_SIGN_UP_STEP_TWO_SUCCESS';
export const USER_SIGN_UP_STEP_TWO_FAILURE = 'USER_SIGN_UP_STEP_TWO_FAILURE';

export const USER_SIGN_UP_CONFIRM_REQUEST = 'USER_SIGN_UP_CONFIRM_REQUEST';
export const USER_SIGN_UP_CONFIRM_SUCCESS = 'USER_SIGN_UP_CONFIRM_SUCCESS';
export const USER_SIGN_UP_CONFIRM_FAILURE = 'USER_SIGN_UP_CONFIRM_FAILURE';

export const USER_SEND_RESET_EMAIL_REQUEST = 'USER_SEND_RESET_EMAIL_REQUEST';
export const USER_SEND_RESET_EMAIL_SUCCESS = 'USER_SEND_RESET_EMAIL_SUCCESS';
export const USER_SEND_RESET_EMAIL_FAILURE = 'USER_SEND_RESET_EMAIL_FAILURE';

export const USER_SET_NEW_PASSWORD_REQUEST = 'USER_SET_NEW_PASSWORD_REQUEST';
export const USER_SET_NEW_PASSWORD_SUCCESS = 'USER_SET_NEW_PASSWORD_SUCCESS';
export const USER_SET_NEW_PASSWORD_FAILURE = 'USER_SET_NEW_PASSWORD_FAILURE';

export const GET_OPTIONS_REQUEST = 'GET_OPTIONS_REQUEST';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_FAILURE = 'GET_OPTIONS_FAILURE';

export const USER_SIGN_OUT_SUCCESS='USER_SIGN_OUT_SUCCESS'
export const USER_SIGN_OUT_FAILURE='USER_SIGN_OUT_FAILURE'

export const USER_SIGN_OUT = 'USER_SIGN_OUT';

export const SIGN_UP_PRODUCT_REQUEST = 'SIGN_UP_PRODUCT_REQUEST'
export const SIGN_UP_PRODUCT_SUCCESS = 'SIGN_UP_PRODUCT_SUCCESS'
export const SIGN_UP_PRODUCT_FAILURE = 'SIGN_UP_PRODUCT_FAILURE'

export const SIGN_UP_DEMO_PRODUCT_REQUEST = 'SIGN_UP_DEMO_PRODUCT_REQUEST'
export const SIGN_UP_DEMO_PRODUCT_SUCCESS = 'SIGN_UP_DEMO_PRODUCT_SUCCESS'
export const SIGN_UP_DEMO_PRODUCT_FAILURE = 'SIGN_UP_DEMO_PRODUCT_FAILURE'

export const SIGN_IN_PRODUCT_REQUEST = 'SIGN_IN_PRODUCT_REQUEST'
export const SIGN_IN_PRODUCT_SUCCESS = 'SIGN_IN_PRODUCT_SUCCESS'
export const SIGN_IN_PRODUCT_FAILURE = 'SIGN_IN_PRODUCT_FAILURE'

export const SIGN_IN_DEMO_PRODUCT_REQUEST = 'SIGN_IN_DEMO_PRODUCT_REQUEST'
export const SIGN_IN_DEMO_PRODUCT_SUCCESS = 'SIGN_IN_DEMO_PRODUCT_SUCCESS'
export const SIGN_IN_DEMO_PRODUCT_FAILURE = 'SIGN_IN_DEMO_PRODUCT_FAILURE'