export const GET_ALGO_DASHBOARD_REQUEST = 'GET_ALGO_DASHBOARD_REQUEST';
export const GET_ALGO_DASHBOARD_SUCCESS = 'GET_ALGO_DASHBOARD_SUCCESS';
export const GET_ALGO_DASHBOARD_FAILURE = 'GET_ALGO_DASHBOARD_FAILURE';
export const GET_ALGO_STATISTIC_REQUEST = 'GET_ALGO_STATISTIC_REQUEST';
export const GET_ALGO_STATISTIC_SUCCESS = 'GET_ALGO_STATISTIC_SUCCESS';
export const GET_ALGO_STATISTIC_FAILURE = 'GET_ALGO_STATISTIC_FAILURE';
export const GET_PNL_CHART_REQUEST = 'GET_PNL_CHART_REQUEST';
export const GET_PNL_CHART_SUCCESS = 'GET_PNL_CHART_SUCCESS';
export const GET_PNL_CHART_FAILURE = 'GET_PNL_CHART_FAILURE';
export const GET_TOTAL_DEPOSIT_DISTRIBUTION_REQUEST = 'GET_TOTAL_DEPOSIT_DISTRIBUTION_REQUEST';
export const GET_TOTAL_DEPOSIT_DISTRIBUTION_SUCCESS = 'GET_TOTAL_DEPOSIT_DISTRIBUTION_SUCCESS';
export const GET_TOTAL_DEPOSIT_DISTRIBUTION_FAILURE = 'GET_TOTAL_DEPOSIT_DISTRIBUTION_FAILURE';
export const GET_TOTAL_PNL_DISTRIBUTION_REQUEST = 'GET_TOTAL_PNL_DISTRIBUTION_REQUEST';
export const GET_TOTAL_PNL_DISTRIBUTION_SUCCESS = 'GET_TOTAL_PNL_DISTRIBUTION_SUCCESS';
export const GET_TOTAL_PNL_DISTRIBUTION_FAILURE = 'GET_TOTAL_PNL_DISTRIBUTION_FAILURE';
export const SET_DEFAULT_STATISTIC = 'SET_DEFAULT_STATISTIC';
export const SET_DEFAULT_CHARTS = 'SET_DEFAULT_CHARTS';
