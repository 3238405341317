import * as algoDepositsActionsTypes from '../../actions/algoActions/deposits/constants';

const initialState = {
    deposits: null,
    isLoading: true,
    processedDeposit: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case algoDepositsActionsTypes.CONFIRM_ALGO_DEPOSIT_REQUEST:
            return {
                ...state,
                processedDeposit: true,
            };
        case algoDepositsActionsTypes.CONFIRM_ALGO_DEPOSIT_SUCCESS:
            return {
                ...state,
                processedDeposit: false
            };
        case algoDepositsActionsTypes.CONFIRM_ALGO_DEPOSIT_FAILURE:
            return {
                ...state,
                processedDeposit: false
            };
        case algoDepositsActionsTypes.GET_ALGO_DEPOSITS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case algoDepositsActionsTypes.GET_ALGO_DEPOSITS_SUCCESS:
            return {
                ...state,
                deposits: action.payload,
                isLoading: false
            };
        case algoDepositsActionsTypes.GET_ALGO_DEPOSITS_FAILURE:
            return {
                ...state,
                deposits: null,
                isLoading: false
            };
        case algoDepositsActionsTypes.SET_DEFAULTS:
            return {
                ...state,
                deposits: null,
                isLoading: true
            };
        default:
            return state
    }
}