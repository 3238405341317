import {notify} from 'react-notify-toast';

let errorColor = { background: '#F6465D', text: "#fff" };
let successColor = { background: '#0ECB81', text: "#fff" };
let warningColor = { background: '#F0B90B', text: "#fff" };
let infoColor = { background: '#2684FF', text: "#fff" };

class Toast {
    constructor(msg, type) {
        this.msg = msg;
        this.show = notify.createShowQueue();
    }
    toastError = () => this.show(this.msg, 'custom', 4000, errorColor);
    toastSuccess = () => this.show(this.msg, 'custom', 3000, successColor);
    toastInfo = () => this.show(this.msg, 'custom', 3000, infoColor);
    toastWarning = () => this.show(this.msg, 'custom', 3000, warningColor);
}

export const toastError = (msg) => {
    return new Toast(msg).toastError()
}

export const toastSuccess = (msg) => {
    return new Toast(msg).toastSuccess()
}

export const toastInfo = (msg) => {
    return new Toast(msg).toastInfo()
}

export const toastWarning = (msg) => {
    return new Toast(msg).toastWarning()
}