import * as userAlgoActions from '../../actions/algoActions/profileActions/constants'

const initialState = {
    personal: null,
    bank: null,
    wallet: null,
    isProcessedProfile: false,
    isLoading: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case userAlgoActions.USER_ALGO_GET_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                isLoading: true,
                personal: null
            };
        case userAlgoActions.USER_ALGO_GET_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                personal: action.payload
            };
        case userAlgoActions.USER_ALGO_GET_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                personal: null
            };
        case userAlgoActions.USER_ALGO_UPDATE_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                isProcessedProfile: true,
            };
        case userAlgoActions.USER_ALGO_UPDATE_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                isProcessedProfile: false,
                personal: action.payload
            };
        case userAlgoActions.USER_ALGO_UPDATE_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                isProcessedProfile: false,
            };
        case userAlgoActions.USER_GET_BANK_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
                bank: null
            };
        case userAlgoActions.USER_GET_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bank: action.payload
            };
        case userAlgoActions.USER_GET_BANK_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false,
                bank: null
            };
        case userAlgoActions.USER_ADD_BANK_DETAILS_REQUEST:
            return {
                ...state,
                isProcessedProfile: true,
            };
        case userAlgoActions.USER_ADD_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                isProcessedProfile: false,
                bank: action.payload
            };
        case userAlgoActions.USER_ADD_BANK_DETAILS_FAILURE:
            return {
                ...state,
                isProcessedProfile: false,
            };
        case userAlgoActions.USER_UPDATE_BANK_DETAILS_REQUEST:
            return {
                ...state,
                isProcessedProfile: true,
            };
        case userAlgoActions.USER_UPDATE_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                isProcessedProfile: false,
                bank: action.payload
            };
        case userAlgoActions.USER_UPDATE_BANK_DETAILS_FAILURE:
            return {
                ...state,
                isProcessedProfile: false,
            };
        case userAlgoActions.USER_GET_WALLET_REQUEST:
            return {
                ...state,
                isLoading: true,
                wallet: null
            };
        case userAlgoActions.USER_GET_WALLET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                wallet: action.payload
            };
        case userAlgoActions.USER_GET_WALLET_FAILURE:
            return {
                ...state,
                isLoading: false,
                wallet: null
            };
        case userAlgoActions.USER_ADD_WALLET_REQUEST:
            return {
                ...state,
                isProcessedProfile: true
            };
        case userAlgoActions.USER_ADD_WALLET_SUCCESS:
            return {
                ...state,
                isProcessedProfile: false,
                wallet: action.payload
            };
        case userAlgoActions.USER_ADD_WALLET_FAILURE:
            return {
                ...state,
                isProcessedProfile: false
            };
        case userAlgoActions.SET_PROFILE_DETAILS_DEFAULT:
            return {
                personal: null,
                bank: null,
                isProcessedProfile: false,
                isLoading: true
            };
        default:
            return state
    }
}