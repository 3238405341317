export const GET_ALGO_PRODUCTS_REQUEST = 'GET_ALGO_PRODUCTS_REQUEST';
export const GET_ALGO_PRODUCTS_SUCCESS = 'GET_ALGO_PRODUCTS_SUCCESS';
export const GET_ALGO_PRODUCTS_FAILURE = 'GET_ALGO_PRODUCTS_FAILURE';

export const GET_ALGO_PRODUCT_REQUEST = 'GET_ALGO_PRODUCT_REQUEST';
export const GET_ALGO_PRODUCT_SUCCESS = 'GET_ALGO_PRODUCT_SUCCESS';
export const GET_ALGO_PRODUCT_FAILURE = 'GET_ALGO_PRODUCT_FAILURE';

export const GET_BANK_FORM_REQUEST = 'GET_BANK_FORM_REQUEST';
export const GET_BANK_FORM_SUCCESS = 'GET_BANK_FORM_SUCCESS';
export const GET_BANK_FORM_FAILURE = 'GET_BANK_FORM_FAILURE';


export const GET_CRYPTO_FORM_REQUEST = 'GET_CRYPTO_FORM_REQUEST';
export const GET_CRYPTO_FORM_SUCCESS = 'GET_CRYPTO_FORM_SUCCESS';
export const GET_CRYPTO_FORM_FAILURE = 'GET_CRYPTO_FORM_FAILURE';


export const CREATE_CRYPTO_PAYMENT_REQUEST = 'CREATE_CRYPTO_PAYMENT_REQUEST';
export const CREATE_CRYPTO_PAYMENT_SUCCESS = 'CREATE_CRYPTO_PAYMENT_SUCCESS';
export const CREATE_CRYPTO_PAYMENT_FAILURE = 'CREATE_CRYPTO_PAYMENT_FAILURE';

export const CREATE_BANK_PAYMENT_REQUEST = 'CREATE_BANK_PAYMENT_REQUEST';
export const CREATE_BANK_PAYMENT_SUCCESS = 'CREATE_BANK_PAYMENT_SUCCESS';
export const CREATE_BANK_PAYMENT_FAILURE = 'CREATE_BANK_PAYMENT_FAILURE';
