import * as authActions from '../../actions/authActions/constants'
import * as profileActions from '../../actions/profileActions/constants'
import {getCookie} from "../../../helpers/cookies";

const initialState = {
    loggedIn: getCookie('loggedIn'),
    user_id: getCookie('user_id'),
    is_email_confirmed: getCookie('is_email_confirmed'),
    accessToken: getCookie('accessToken'),
    user_public_id: getCookie('user_public_id'),
    first_name: getCookie('first_name'),
    last_name: getCookie('last_name'),
    user_name: getCookie('first_name') + ' ' + getCookie('last_name'),
    isProcessedLogin: false,
    isProcessedDemoLogin: false,
    product: null,
    user: {
        first_name: '',
        last_name: '',
        country: '',
        email: '',
        account_type: '',
        phone_number: ''
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case profileActions.USER_UPDATE_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                isProcessedLogin: true
            }
        case profileActions.USER_UPDATE_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                isProcessedLogin: false
            }
        case authActions.SIGN_UP_PRODUCT_REQUEST:
            return {
                ...state,
                product: false,
                isProcessedLogin: true
            }
        case authActions.SIGN_UP_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                isProcessedLogin: false
            }
        case authActions.SIGN_UP_PRODUCT_FAILURE:
            return {
                ...state,
                product: null,
                isProcessedLogin: false
            }
        case authActions.SIGN_UP_DEMO_PRODUCT_REQUEST:
            return {
                ...state,
                product: false,
                isProcessedLogin: true
            }
        case authActions.SIGN_UP_DEMO_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                isProcessedLogin: false
            }
        case authActions.SIGN_UP_DEMO_PRODUCT_FAILURE:
            return {
                ...state,
                product: null,
                isProcessedLogin: false
            }
        case authActions.SIGN_IN_PRODUCT_REQUEST:
            return {
                ...state,
                product: false,
                isProcessedLogin: true
            }
        case authActions.SIGN_IN_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                isProcessedLogin: false
            }
        case authActions.SIGN_IN_PRODUCT_FAILURE:
            return {
                ...state,
                product: null,
                isProcessedLogin: false
            }
        case authActions.SIGN_IN_DEMO_PRODUCT_REQUEST:
            return {
                ...state,
                product: false,
                isProcessedDemoLogin: true
            }
        case authActions.SIGN_IN_DEMO_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                isProcessedDemoLogin: false
            }
        case authActions.SIGN_IN_DEMO_PRODUCT_FAILURE:
            return {
                ...state,
                product: null,
                isProcessedDemoLogin: false
            }
        case profileActions.SET_DEFAULTS:
            return {
                ...state,
                isLoading: true,
                wallet: ''
            };
        case authActions.USER_SIGN_IN_REQUEST:
            return {
                ...state,
                isProcessedLogin: true
            };
        case authActions.USER_SIGN_IN_SUCCESS:
            return {
                ...state,
                processed: false,
                isProcessedLogin: false,
                is_email_confirmed: action.payload.is_email_confirmed,
                nationality: action.payload.country,
                phone_number: action.payload.phone_number,
                loggedIn: true,
                accessToken: action.payload.access_token,
                user_id: action.payload.public_id,
                user_name: action.payload.user_name?.first_name + ' ' + action.payload.user_name?.last_name,
                user_public_id: action.payload.public_id,
                user: {
                    first_name: action.payload.user_name?.first_name,
                    last_name: action.payload.user_name?.last_name,
                    country: action.payload.country,
                    phone_number: action.payload.phone_number,
                    email: action.payload.email,
                    account_type: action.payload.account_type || 0
                }
            };
        case profileActions.USER_UPDATE_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                isProcessedLogin: false,
                user: {
                    first_name: action.payload.user_name?.first_name,
                    last_name: action.payload.user_name?.last_name,
                    country: action.payload.country,
                    phone_number: action.payload.phone_number,
                    email: action.payload.email,
                    account_type: action.payload.account_type
                }
            };
        case authActions.USER_SIGN_IN_FAILURE:
            return {
                ...state,
                isProcessedLogin: false
            };

        case authActions.USER_SIGN_UP_REQUEST:
            return {
                ...state,
                isProcessedLogin: true
            }
        case authActions.USER_SIGN_UP_SUCCESS:
            return {
                ...state,
                processed: false,
                isProcessedLogin: false,
                is_email_confirmed: action.payload.is_email_confirmed,
                nationality: action.payload.country,
                phone_number: action.payload.phone_number,
                loggedIn: true,
                accessToken: action.payload.access_token,
                user_id: action.payload.public_id,
                user_name: action.payload.user_name?.first_name + ' ' + action.payload.user_name?.last_name,
                user_public_id: action.payload.public_id,
                user: {
                    first_name: action.payload.user_name?.first_name,
                    last_name: action.payload.user_name?.last_name,
                    phone_number: action.payload.phone_number,
                    country: action.payload.country,
                    email: action.payload.email,
                    account_type: action.payload.account_type || 0
                }
            };
        case authActions.USER_SIGN_UP_FAILURE:
            return {
                ...state,
                isProcessedLogin: false
            }
        case authActions.USER_CONFIRM_EMAIL_ACTION_REQUEST:
            return {
                ...state,
                confirmLoading: true,
            };
        case authActions.USER_CONFIRM_EMAIL_ACTION_SUCCESS:
            return {
                ...state,
                confirmLoading: false,
                confirmEmailMessage: 'Your email has been confirmed!'
            };
        case authActions.USER_CONFIRM_EMAIL_ACTION_FAILURE:
            return {
                ...state,
                confirmLoading: false,
                confirmEmailMessage: action.payload || 'Incorrect information or the link is out of date. Log in to your account and send a new confirmation request.'
            };

        case profileActions.USER_GET_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case profileActions.USER_GET_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: {
                    first_name: action.payload.user_name?.first_name,
                    last_name: action.payload.user_name?.last_name,
                    phone_number: action.payload.phone_number,
                    country: action.payload.country,
                    email: action.payload.email,
                    account_type: action.payload.account_type || 0
                },
            };
        case profileActions.USER_GET_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                user: {
                    first_name: '',
                    last_name: '',
                    country: '',
                    email: '',
                    account_type: '',
                    phone_number: ''
                },
            };

        case authActions.USER_SEND_RESET_EMAIL_REQUEST:
            return {
                ...state,
                isProcessedLogin: true
            };
        case authActions.USER_SEND_RESET_EMAIL_SUCCESS:
            return {
                ...state,
                isProcessedLogin: false,
            };
        case authActions.USER_SEND_RESET_EMAIL_FAILURE:
            return {
                ...state,
                isProcessedLogin: false,
            };

        case authActions.USER_SET_NEW_PASSWORD_REQUEST:
            return {
                ...state,
                isProcessedLogin: true
            };
        case authActions.USER_SET_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                isProcessedLogin: false,
            };
        case authActions.USER_SET_NEW_PASSWORD_FAILURE:
            return {
                ...state,
                isProcessedLogin: false,
            };

        case profileActions.USER_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                isProcessedChangeProfile: true
            };
        case profileActions.USER_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isProcessedChangeProfile: false,
            };
        case profileActions.USER_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                isProcessedChangeProfile: false,
            };

        case authActions.USER_SIGN_OUT:
            return initialState;
        case authActions.USER_SIGN_OUT_SUCCESS:
            return {
                ...state,
                loggedIn: false,
                user_id: '',
                wallet: '',
                enable_2fa: false,
                processed: false,
                accessToken: '',
                isAuthorized: false,
                refreshToken: '',
                user_name: '',
                user_public_id: ''
            };
        default:
            return state
    }
}