export const GET_OPTIONS_REQUEST = 'GET_OPTIONS_REQUEST';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_FAILURE = 'GET_OPTIONS_FAILURE';

export const GET_CURRENCIES_REQUEST = 'GET_CURRENCIES_REQUEST';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAILURE = 'GET_CURRENCIES_FAILURE';

export const GET_PLATFORMS_REQUEST = 'GET_PLATFORMS_REQUEST';
export const GET_PLATFORMS_SUCCESS = 'GET_PLATFORMS_SUCCESS';
export const GET_PLATFORMS_FAILURE = 'GET_PLATFORMS_FAILURE';

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const CLEAR_PLATFORMS = 'CLEAR_PLATFORMS';