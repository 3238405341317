import * as withdrawalsActionsTypes from '../../actions/algoActions/withdrawal/constants';

const initialState = {
    withdrawals_list: null,
    available_withdrawals: null,
    isLoading: true,
    isLoadingAvailable: false,
    processedWithdrawal: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case withdrawalsActionsTypes.WITHDRAWALS_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case withdrawalsActionsTypes.WITHDRAWALS_LIST_SUCCESS:
            return {
                ...state,
                withdrawals_list: action.payload,
                isLoading: false
            };
        case withdrawalsActionsTypes.WITHDRAWALS_LIST_FAILURE:
            return {
                ...state,
                withdrawals_list: null,
                isLoading: false
            };
        case withdrawalsActionsTypes.GET_AVAILABLE_WITHDRAWALS_REQUEST:
            return {
                ...state,
                isLoadingAvailable: true
            };
        case withdrawalsActionsTypes.GET_AVAILABLE_WITHDRAWALS_SUCCESS:
            return {
                ...state,
                available_withdrawals: action.payload,
                isLoadingAvailable: false
            };
        case withdrawalsActionsTypes.GET_AVAILABLE_WITHDRAWALS_FAILURE:
            return {
                ...state,
                available_withdrawals: null,
                isLoadingAvailable: false
            };
        case withdrawalsActionsTypes.USER_WITHDRAWAL_ACTION_REQUEST:
            return {
                ...state,
                processedWithdrawal: true
            };
        case withdrawalsActionsTypes.USER_WITHDRAWAL_ACTION_SUCCESS:
            return {
                ...state,
                processedWithdrawal: false
            };
        case withdrawalsActionsTypes.USER_WITHDRAWAL_ACTION_FAILURE:
            return {
                ...state,
                processedWithdrawal: false
            };
        case withdrawalsActionsTypes.SET_WITHDRAWALS_DEFAULTS:
            return {
                ...state,
                withdrawals_list: null,
                isLoading: true
            };
        default:
            return state
    }
}