import {applyMiddleware, compose, createStore,} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import {persistStore} from 'redux-persist'

import reducers from './reducers'
import {getCookie} from "../helpers/cookies";

import {userSignOutSuccess} from "./actions/authActions";
import { BroadcastChannel } from 'broadcast-channel';
const debug = true;

function middleware() {
    if (debug) {
        return [
            thunkMiddleware,
        ]
    } else {
        return [thunkMiddleware]
    }
}

let middlewares;

if (debug) {
    middlewares = composeWithDevTools(applyMiddleware(...middleware()))
} else {
    middlewares = compose(applyMiddleware(...middleware()))
}

export const store = createStore(
    reducers,
    undefined,
    middlewares
);


let lastCookie = document.cookie;

// rename document.cookie to document._cookie, and redefine document.cookie
const expando = '_cookie';
let nativeCookieDesc = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie');
Object.defineProperty(Document.prototype, expando, nativeCookieDesc);
Object.defineProperty(Document.prototype, 'cookie', {
    enumerable: true,
    configurable: true,
    get() {
        return this[expando];
    },
    set(value) {
        this[expando] = value;
        // check cookie change
        let cookie = this[expando];
        if (cookie !== lastCookie) {
            try {
                // dispatch cookie-change messages to other same-origin tabs/frames
                let detail = {oldValue: lastCookie, newValue: cookie};
                this.dispatchEvent(new CustomEvent('cookiechange', {
                    detail: detail
                }));
                channel.postMessage(detail);
            } finally {
                lastCookie = cookie;
            }
        }
    }
});
// listen cookie-change messages from other same-origin tabs/frames
const channel = new BroadcastChannel('cookie-channel');
channel.onmessage = (e) => {
    lastCookie = e.newValue;
    document.dispatchEvent(new CustomEvent('cookiechange', {
        detail: e
    }));
};

document.addEventListener('cookiechange', ({detail: {oldValue, newValue}}) => {
    console.log('cookies change')
    let loggedIn = getCookie('loggedIn');

    if (!loggedIn) {
        store.dispatch(userSignOutSuccess())
    } else {
        setTimeout(() => {
            if (window.location.pathname === '/sign-in') {
                window.location.reload()
            }
        }, 500)
    }
});

export const persistor = persistStore(store).subscribe(() => {});