import * as dashboardActionsTypes from '../../actions/algoActions/dashboard/constants';

const initialState = {
    statistic: null,
    isLoadingStatistic: true,
    pnlChart: null,
    isLoadingPnlChart: true,
    totalPnlDistribution: null,
    totalDepositDistribution: null,
    isLoadingTotalDistribution: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case dashboardActionsTypes.GET_ALGO_STATISTIC_REQUEST:
            return {
                ...state,
                isLoadingStatistic: true,
            };
        case dashboardActionsTypes.GET_ALGO_STATISTIC_SUCCESS:
            return {
                ...state,
                statistic: action.payload,
                isLoadingStatistic: false
            };
        case dashboardActionsTypes.GET_ALGO_STATISTIC_FAILURE:
            return {
                ...state,
                statistic: null,
                isLoadingStatistic: false
            };
        case dashboardActionsTypes.GET_PNL_CHART_REQUEST:
            return {
                ...state,
                isLoadingPnlChart: true,
            };
        case dashboardActionsTypes.GET_PNL_CHART_SUCCESS:
            return {
                ...state,
                pnlChart: action.payload,
                isLoadingPnlChart: false
            };
        case dashboardActionsTypes.GET_PNL_CHART_FAILURE:
            return {
                ...state,
                pnlChart: null,
                isLoadingPnlChart: false
            };
        case dashboardActionsTypes.GET_TOTAL_PNL_DISTRIBUTION_REQUEST:
            return {
                ...state,
                totalDepositDistribution: null,
                totalPnlDistribution: null,
                isLoadingTotalDistribution: true,
            };
        case dashboardActionsTypes.GET_TOTAL_PNL_DISTRIBUTION_SUCCESS:
            return {
                ...state,
                totalPnlDistribution: action.payload,
                isLoadingTotalDistribution: false
            };
        case dashboardActionsTypes.GET_TOTAL_PNL_DISTRIBUTION_FAILURE:
            return {
                ...state,
                totalPnlDistribution: null,
                isLoadingTotalDistribution: false
            };
        case dashboardActionsTypes.GET_TOTAL_DEPOSIT_DISTRIBUTION_REQUEST:
            return {
                ...state,
                totalPnlDistribution: null,
                totalDepositDistribution: null,
                isLoadingTotalDistribution: true,
            };
        case dashboardActionsTypes.GET_TOTAL_DEPOSIT_DISTRIBUTION_SUCCESS:
            return {
                ...state,
                totalDepositDistribution: action.payload,
                isLoadingTotalDistribution: false
            };
        case dashboardActionsTypes.GET_TOTAL_DEPOSIT_DISTRIBUTION_FAILURE:
            return {
                ...state,
                totalDepositDistribution: null,
                isLoadingTotalDistribution: false
            };
        case dashboardActionsTypes.SET_DEFAULT_STATISTIC:
            return {
                ...state,
                isLoadingStatistic: true
            };
        case dashboardActionsTypes.SET_DEFAULT_CHARTS:
            return {
                ...state,
                pnlChart: null,
                isLoadingPnlChart: true,
                totalDistribution: null,
                isLoadingTotalDistribution: true,
            };
        default:
            return state
    }
}