import * as algoActionsTypes from '../../actions/algoActions/products/constants';

const initialState = {
    products: null,
    product: null,
    crypto_form: null,
    bank_form: null,
    isLoading: true,
    paymentPopupData: null,
    paymentBankPopupData: null,
    isProcessedPayment: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case algoActionsTypes.GET_ALGO_PRODUCTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case algoActionsTypes.GET_ALGO_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload,
                isLoading: false
            };
        case algoActionsTypes.GET_ALGO_PRODUCTS_FAILURE:
            return {
                ...state,
                products: null,
                isLoading: false
            };
        case algoActionsTypes.GET_ALGO_PRODUCT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case algoActionsTypes.GET_ALGO_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
                isLoading: false
            };
        case algoActionsTypes.GET_ALGO_PRODUCT_FAILURE:
            return {
                ...state,
                product: null,
                isLoading: false
            };
        case algoActionsTypes.GET_CRYPTO_FORM_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case algoActionsTypes.GET_CRYPTO_FORM_SUCCESS:
            return {
                ...state,
                crypto_form: action.payload,
                isLoading: false
            };
        case algoActionsTypes.GET_CRYPTO_FORM_FAILURE:
            return {
                ...state,
                crypto_form: null,
                isLoading: false
            };
        case algoActionsTypes.GET_BANK_FORM_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case algoActionsTypes.GET_BANK_FORM_SUCCESS:
            return {
                ...state,
                bank_form: action.payload,
                isLoading: false
            };
        case algoActionsTypes.GET_BANK_FORM_FAILURE:
            return {
                ...state,
                crypto_form: null,
                isLoading: false
            };
        case algoActionsTypes.CREATE_CRYPTO_PAYMENT_REQUEST:
            return {
                ...state,
                isProcessedPayment: true,
            };
        case algoActionsTypes.CREATE_CRYPTO_PAYMENT_SUCCESS:
            return {
                ...state,
                paymentPopupData: action.payload,
                isProcessedPayment: false
            };
        case algoActionsTypes.CREATE_CRYPTO_PAYMENT_FAILURE:
            return {
                ...state,
                paymentPopupData: null,
                isProcessedPayment: false
            };
        case algoActionsTypes.CREATE_BANK_PAYMENT_REQUEST:
            return {
                ...state,
                isProcessedPayment: true,
            };
        case algoActionsTypes.CREATE_BANK_PAYMENT_SUCCESS:
            return {
                ...state,
                paymentBankPopupData: action.payload,
                isProcessedPayment: false
            };
        case algoActionsTypes.CREATE_BANK_PAYMENT_FAILURE:
            return {
                ...state,
                paymentBankPopupData: null,
                isProcessedPayment: false
            };
        default:
            return state
    }
}