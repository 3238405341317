import * as algoTradesActionsTypes from '../../actions/algoActions/trades/constants';

const initialState = {
    trades: null,
    isLoading: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case algoTradesActionsTypes.GET_ALGO_TRADES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case algoTradesActionsTypes.GET_ALGO_TRADES_SUCCESS:
            return {
                ...state,
                trades: action.payload,
                isLoading: false
            };
        case algoTradesActionsTypes.GET_ALGO_TRADES_FAILURE:
            return {
                ...state,
                trades: null,
                isLoading: false
            };
        case algoTradesActionsTypes.SET_DEFAULTS:
            return {
                ...state,
                trades: null,
                isLoading: true
            };
        default:
            return state
    }
}