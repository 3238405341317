import React from 'react';
import Aux from "../../hoc/_Aux";
import '../../assets/scss/style.scss';
import Logo from "../components/Authentication/Logo";

const Page404 = () => {
    return (
        <Aux>
            <div className="not_found">
                <div className="container">
                    <Logo/>
                </div>
                <div className="not_found_content_wrapper">

                    <div className="container">
                        <div className="not_found_content">
                            <div style={{fontSize: '40px'}} className=""
                                 data-count="404">404
                            </div>
                            <div className="">Page not found</div>
                            <div className="">This may not mean anything.</div>
                            <div className="">I'm probably working on something that has
                                blown up.
                            </div>
                            <div className="">
                                <a href={'/'}>
                                    <button
                                        className='cta'
                                    >Go to Home Page
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    )
};

export default Page404;