export const USER_GET_PERSONAL_INFO_REQUEST = 'USER_GET_PERSONAL_INFO_REQUEST';
export const USER_GET_PERSONAL_INFO_SUCCESS = 'USER_GET_PERSONAL_INFO_SUCCESS';
export const USER_GET_PERSONAL_INFO_FAILURE = 'USER_GET_PERSONAL_INFO_FAILURE';

export const USER_UPDATE_PERSONAL_INFO_REQUEST = 'USER_UPDATE_PERSONAL_INFO_REQUEST';
export const USER_UPDATE_PERSONAL_INFO_SUCCESS = 'USER_UPDATE_PERSONAL_INFO_SUCCESS';
export const USER_UPDATE_PERSONAL_INFO_FAILURE = 'USER_UPDATE_PERSONAL_INFO_FAILURE';

export const GET_USER_WALLET_REQUEST = 'GET_USER_WALLET_REQUEST';
export const GET_USER_WALLET_SUCCESS = 'GET_USER_WALLET_SUCCESS';
export const GET_USER_WALLET_FAILURE = 'GET_USER_WALLET_FAILURE';

export const ADD_USER_WALLET_REQUEST = 'ADD_USER_WALLET_REQUEST';
export const ADD_USER_WALLET_SUCCESS = 'ADD_USER_WALLET_SUCCESS';
export const ADD_USER_WALLET_FAILURE = 'ADD_USER_WALLET_FAILURE';

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAILURE = 'USER_CHANGE_PASSWORD_FAILURE';

export const GET_USER_OPTIONS_REQUEST = 'GET_USER_OPTIONS_REQUEST';
export const GET_USER_OPTIONS_SUCCESS = 'GET_USER_OPTIONS_SUCCESS';
export const GET_USER_OPTIONS_FAILURE = 'GET_USER_OPTIONS_FAILURE';

export const GET_PRODUCT_OPTIONS_REQUEST = 'GET_PRODUCT_OPTIONS_REQUEST';
export const GET_PRODUCT_OPTIONS_SUCCESS = 'GET_PRODUCT_OPTIONS_SUCCESS';
export const GET_PRODUCT_OPTIONS_FAILURE = 'GET_PRODUCT_OPTIONS_FAILURE';

export const GET_USER_REFERRALS_REQUEST = 'GET_USER_REFERRALS_REQUEST';
export const GET_USER_REFERRALS_SUCCESS = 'GET_USER_REFERRALS_SUCCESS';
export const GET_USER_REFERRALS_FAILURE = 'GET_USER_REFERRALS_FAILURE';

export const SET_DEFAULTS = 'SET_DEFAULTS';
export const CLEAR_PLATFORMS = 'CLEAR_PLATFORMS';