import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

//reducers
import user from './userReducer';
import options from './optionsReducer';
import algo from './algoReducer';
import algoDeposits from './algoDepositsReducer';
import algoTrades from './algoTradesReducer';
import ws from './wsReducer';
import algoUser from './userAlgoReducer';
import withdrawal from './withdrawalReducer';
import dashboard from './dashboardReducer';

const rootPersistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['options', 'user'],
};

const rootReducer = combineReducers({
    user,
    options,
    algo,
    algoDeposits,
    algoTrades,
    ws,
    algoUser,
    withdrawal,
    dashboard
});

export default persistReducer(rootPersistConfig, rootReducer)
